<template>
  <modal
    class="z-[70]"
    data-testid="boost-modal"
    @close="$emit('close')"
  >
    <div
      v-if="showTitle"
      class="max-w-xl w-full font-semibold text-3xl mb-5"
    >
      สิทธิพิเศษนี้ใช้ได้กับคนที่ซื้อบูสต์เท่านั้น
    </div>
    <div class="max-w-xl w-full">
      <div class="flex flex-col lg:flex-row justify-center items-center">
        <img
          src="/boost/boost.png"
          class="w-40 mb-5 mr-0 lg:mb-0 lg:mr-5"
        >
        <div>
          <div class="flex justify-center items-center gap-x-2">
            <img
              src="/boost/boost-logo.png"
              class="w-10"
            >
            <h2 class="font-semibold text-3xl text-secondary">
              DrinksOnMe Boost
            </h2>
          </div>
          <ul
            role="list"
            class="my-4"
          >
            <li
              v-for="(feature, index) in features"
              :key="index"
              class="flex gap-x-3 my-2"
            >
              <img
                src="/landing/tick.png"
                alt="icon"
                class="h-8"
              >
              <div class="text-left">
                <p class="text-xl">
                  {{ feature.text ? feature.text : feature }}
                </p>
                <p v-if="feature.subText">
                  {{ feature.subText }}
                </p>
              </div>
            </li>
          </ul>
          <!-- <div class="mt-8 mb-5 flex justify-center">
            <fieldset class="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200">
              <legend class="sr-only">
                Boost period
              </legend>

              <label v-for="(bp, index) in boostPackages" :key="index" class="cursor-pointer rounded-full py-1 px-2.5 boost-period-radio" :class="selectedPackageIndex === index ? 'text-white bg-black' : 'bg-cream text-black'">
                <input v-model="selectedPackageIndex" type="radio" name="boost-period" :value="index" class="sr-only">
                <p class="font-semibold">{{ bp.label }}<span class="text-secondary ml-1">{{ bp.highlight }}</span></p>
              </label>
            </fieldset>
          </div> -->
          <div class="flex justify-center">
            <dom-button
              class="py-3 px-5 w-48"
              @click.native="goToBoost"
            >
              ไปหน้าซื้อ Boost
            </dom-button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import global from '~/mixins/global'
import DomButton from '~/components/DomButton.vue'
import Modal from '~/components/common/Modal.vue'

export default {
  components: {
    DomButton,
    Modal,
  },
  mixins: [global],
  props: {
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      features: ['ไม่มีโฆษณา', 'Dark mode', 'ฟรี! เลี้ยงเครื่องดื่มได้ 1 ครั้ง/วัน', 'สิทธิ์ในการเข้าห้องสิง ฟังเพลง', { text: 'เปิดโต๊ะใหม่ และเป็นเจ้าของโต๊ะ', subText: 'ควบคุมการเพิ่มลดเพลงในห้องได้' }, { text: 'รีพอร์ตได้ 3 ครั้ง/วัน', subText: '(ยูสเซอร์ปกติได้แค่ 1 ครั้งต่อวัน)' }, 'ผ้าพันคอ', 'หมวกที่เปลี่ยนตามเทศกาล'],
    }
  },
  methods: {
    goToBoost() {
      this.$emit('close')
      window.location = '/boost'
    },
  },
}
</script>
