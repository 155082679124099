<template>
  <modal @close="$emit('close')" :close-disabled="!user.username">
    <p class="text-2xl text-center font-semibold mb-2 mx-16">
      {{ user.username ? 'อัพเดตโปรไฟล์' : 'เพื่อให้ประสบการณ์การใช้งานของคุณดีขึ้น กรุณากรอกข้อมูลโปรไฟล์' }}
    </p>
    <p class="text text-paragraph-gray text-center">
      ข้อมูลต่างๆจะแสดงที่ profile ของคุณเท่านั้น
    </p>
    <p v-if="!user.username" class="text text-paragraph-gray text-center">
      และสามารถเปลี่ยนแปลงในภายหลังได้
    </p>
    <div class="w-full mt-4 px-4 flex flex-col items-start text-left lg:px-12">
      <div class="text-xl font-semibold">
        <span>Username</span>
        <span class="text-red-500">*</span>
      </div>
      <div class="flex items-center mt-2 mb-1 bg-white rounded-lg">
        <span>@</span>
        <input
          id="username"
          type="text"
          class="w-full rounded-lg "
          maxlength="15"
          v-model="usernameTextField"
        />
      </div>
      <div class="text-sm text-paragraph-gray">- จำกัดเฉพาะตัวอักษรภาษาอังกฤษ ตัวเลข และ _ เท่านั้น</div>
      <div class="text-sm text-paragraph-gray">- ความยาวตั้งแต่ 4 ถึง 15 ตัวอักษร</div>
      <div class="text-xl font-semibold mt-4">
        <span>ความสนใจ</span>
      </div>
      <textarea
        id="interests"
        type="text"
        class="w-full rounded-lg mt-2 mb-1"
        maxlength="140"
        v-model="interestsTextField"
      />
      <div class="text-sm text-paragraph-gray">- ความยาวสูงสุด 140 ตัวอักษร</div>
    </div>
    <dom-button
      class="mx-auto mt-6 py-4 w-48 text-xl"
      @click.native="onClickUpdateProfile"
    >
      อัพเดต
    </dom-button>
  </modal>
</template>

<script>
import Modal from '~/components/common/Modal.vue'
import DomButton from '~/components/DomButton.vue'
import global from '~/mixins/global.js'

export default {
  mixins: [global],
  components: {
    Modal,
    DomButton,
  },
  data() {
    return {
      usernameTextField: '',
      interestsTextField: '',
    }
  },
  mounted() {
    this.usernameTextField = this.user.username || ''
    this.interestsTextField = this.user.interests || ''
  },
  methods: {
    async onClickUpdateProfile() {
      if (!this.validateUserName(this.usernameTextField)) {
        return
      }
      if (!this.validateInterests(this.interestsTextField)) {
        return
      }
      if (this.isInappropriateContent(this.interestsTextField)) {
        this.$notify({
          type: 'error',
          text: 'คำที่คุณใช้ไม่เหมาะสม กรุณาลองใหม่อีกครั้ง',
        })
        return
      }

      try {
        await $fetch('/api/user/profile', {
          method: 'POST',
          baseURL: this.$config.public.appBaseURL,
          headers: this.headers,
          body: {
            username: this.usernameTextField,
            interests: this.interestsTextField,
          },
        })
        this.$emit('close')
        this.$notify({
          type: 'success',
          text: 'อัพเดตโปรไฟล์สำเร็จ',
        })
        this.usernameTextField = ''
        this.interestsTextField = ''
      } catch (error) {
        switch (error?.response?._data?.message) {
          case 'duplicated-username':
            this.$notify({
              type: 'error',
              text: 'username นี้ถูกใช้ไปแล้ว',
            })
            break
          case 'invalid-username':
            this.$notify({
              type: 'error',
              text: 'username ต้องมีตัวอักษรเป็นภาษาอังกฤษ, ตัวเลข หรือ _ เท่านั้น และมีความยาวตั้งแต่ 4 ถึง 15 ตัวอักษร',
            })
            break
          case 'invalid-interests':
            this.$notify({
              type: 'error',
              text: 'ความสนใจต้องมีความยาวน้อยกว่า 140 ตัวอักษรเท่านั้น',
            })
            break
          default:
            this.$notify({
              type: 'error',
              text: 'เกิดข้อผิดพลาดในการอัพเดตโปรไฟล์',
            })
        }

      }
    },
    validateUserName(username) {
      if (username.length === 0) {
        this.$notify({
          type: 'error',
          text: 'username ห้ามว่าง',
        })
        return false
      }

      if (username.length < 4 || username.length > 15) {
        this.$notify({
          type: 'error',
          text: 'username ต้องมีความยาวตั้งแต่ 4 ถึง 15 ตัวอักษร',
        })
        return false
      }

      if (!/^[a-zA-Z0-9_]+$/.test(username)) {
        this.$notify({
          type: 'error',
          text: 'username ต้องมีตัวอักษรเป็นภาษาอังกฤษ, ตัวเลข หรือ _ เท่านั้น',
        })
        return false
      }

      return true
    },
    validateInterests(interests) {
      if (interests.length > 140) {
        this.$notify({
          type: 'error',
          text: 'ความสนใจต้องมีความยาวน้อยกว่า 140 ตัวอักษรเท่านั้น',
        })
        return false
      }
      return true
    },
  },
}
</script>
