<template>
  <div class="settings-box dom-border bg-cream">
    <audio
      ref="drinks"
      style="margin: 0 auto;"
      :src="isIos ? 'https://ikuzbipnfkbymlctljcv.supabase.co/storage/v1/object/public/sound/preparing_drinks_min_ios.mp3' : 'https://ikuzbipnfkbymlctljcv.supabase.co/storage/v1/object/public/sound/preparing-drinks-min.mp3'"
      loop
    />
    <audio
      ref="people"
      style="margin: 0 auto;"
      :src="isIos ? 'https://ikuzbipnfkbymlctljcv.supabase.co/storage/v1/object/public/sound/people_min_ios.mp3' : 'https://ikuzbipnfkbymlctljcv.supabase.co/storage/v1/object/public/sound/people-min.mp3'"
      loop
    />
    <background-music
      :should-play="!storeCounterMusic"
      :playlist="isChristmasEvent ? 'PLcRgKvbn--wFGsu4nB_ajky8ajdVMgpF0' : 'PLsnJOHsVvrjhQZdOiR0oIsGgilIAAFzEo'"
    />
    <div class="mx-auto">
      <div class="flex items-center flex-col">
        <p class="text-2xl mb-3">
          เสียง
        </p>
        <div class="flex">
          <label class="text-xl mr-3">
            ปิดเสียง
          </label>
          <our-switch
            :value="storeMuted"
            @set="onChangeMuted"
          />
        </div>
      </div>
      <div v-if="!isIos">
        <div class="slidecontainer">
          <p>
            เสียงเตรียมเครื่องดื่ม
          </p>
          <input
            v-model="drinksVolFromSlider"
            type="range"
            min="0"
            max="100"
            class="slider"
          >
        </div>
        <div class="slidecontainer">
          <p>
            เสียงคนในร้าน
          </p>
          <input
            v-model="peopleVolFromSlider"
            type="range"
            min="0"
            max="100"
            class="slider"
          >
        </div>
        <div class="slidecontainer">
          <p>
            เสียงแจ้งเตือน
          </p>
          <input
            v-model="notificationVolFromSlider"
            type="range"
            min="0"
            max="100"
            class="slider"
          >
        </div>
        <div class="slidecontainer">
          <p>
            เสียงเพลง
          </p>
          <input
            v-model="musicVolFromSlider"
            type="range"
            min="0"
            max="100"
            class="slider"
          >
        </div>
      </div>
    </div>
    <div class="flex justify-center items-center text-2xl mt-8 mb-3">
      Dark mode <img
        src="/boost/boost-logo.png"
        class="ml-1 w-6 h-6"
      >
    </div>
    <div class="flex items-center justify-center mb-3">
      <div class="mr-3 text-xl">
        Light
      </div>
      <our-switch
        :key="switchKey"
        :value="showDarkMode"
        @set="onClickDarkModeSwitch"
      />
      <div class="ml-3 text-xl">
        Dark
      </div>
    </div>
  </div>
</template>

<script>
import global from '~/mixins/global.js'
import OurSwitch from '~/components/common/OurSwitch.vue'
import BackgroundMusic from '~/components/BackgroundMusic.vue'
export default {
  components: {
    OurSwitch,
    BackgroundMusic,
  },
  mixins: [global],
  data() {
    return {
      soundIds: ['drinks', 'people'],
      drinksVolFromSlider: this.$store.volume.drinks,
      peopleVolFromSlider: this.$store.volume.people,
      notificationVolFromSlider: this.$store.volume.notification,
      musicVolFromSlider: this.$store.volume.music,
      currentPlaylist: 0,
      playlistUrls: [
        'https://open.spotify.com/embed/playlist/2DQu4yw3Bx58eNEZYIevDl?utm_source=generator',
        'https://open.spotify.com/embed/playlist/7Bg9W2mPUH4dkwhddHDvFK?utm_source=generator',
        'https://open.spotify.com/embed/playlist/4tJ5q6xFg4dACbauIkjTCJ?utm_source=generator',
      ],
      switchKey: 0, // to force re-render of switch
    }
  },
  computed: {
    storeMusicVol() {
      return this.$store.volume.music
    },
    storeMuted() {
      return this.$store.volume.isMuted
    },
    isIos() {
      return /iPhone|iPad|iPod/i.test(
        navigator?.userAgent,
      )
    },
    storeCounterMusic() {
      return this.$store.counterMusic
    },
  },
  watch: {
    drinksVolFromSlider(newVal) {
      this.setVolume('drinks', newVal)
    },
    peopleVolFromSlider(newVal) {
      this.setVolume('people', newVal)
    },
    notificationVolFromSlider(newVal) {
      this.$store.adjustVolume({ notification: newVal })
    },
    musicVolFromSlider(newVal) {
      this.$store.adjustVolume({ music: newVal })
    },
    storeMuted(newVal) {
      this.soundIds.forEach((id) => {
        if (this.$refs[id]) {
          if (!newVal) {
            this.$refs[id].play().catch((error) => {
              if (error.message?.includes('play() failed because the user didn\'t interact with the document first.')) {
                console.warn(`can not play ${id} sound:`, error)
              }
              else {
                console.error(`Error playing ${id}:`, error)
              }
              this.$store.setMuted(true)
            })
          }
          this.$refs[id].muted = newVal
        }
      })
    },
  },
  mounted() {
    this.setVolume('drinks', this.drinksVolFromSlider)
    this.setVolume('people', this.peopleVolFromSlider)

    this.smoothenAudio('drinks')
    this.smoothenAudio('people')
    if (!this.isSoundMuted) {
      this.soundIds.forEach((id) => {
        if (this.$refs[id]) {
          this.$refs[id].play().catch((error) => {
            if (error.message?.includes('play() failed because the user didn\'t interact with the document first.')) {

              console.warn(`can not play ${id} sound:`, error)
            }
            else {
              console.error(`Error playing ${id}:`, error)
            }
            this.$store.setMuted(true)
          })
        }
      })
    }
  },
  methods: {
    onChangeMuted(value) {
      this.$store.setMuted(value)
    },
    setVolume(id, val) {
      if (this.$refs[id]) {
        this.$refs[id].volume = val / 100
        this.$store.adjustVolume({ [id]: val })
      }
    },
    smoothenAudio(elementId) {
      const audioControl = this.$refs[elementId]
      if (audioControl) {
        audioControl.addEventListener('timeupdate', function () {
          const buffer = 0.6
          if (this.currentTime > this.duration - buffer) {
            this.currentTime = 0
            this.play().catch(error => console.error(`Error looping ${elementId}:`, error))
          }
        })
      }
    },
    async onClickDarkModeSwitch() {
      try {
        const data = await $fetch('/api/user/dark-mode', {
          method: 'POST',
          baseURL: this.$config.public.appBaseURL,
          body: {
            darkMode: !this.showDarkMode,
          },
          headers: this.headers,
        })
        this.$store.setUser(data)
      }
      catch (error) {
        if (error.data?.message === 'not-boosted') {
          this.switchKey++
          this.$store.setShowBoostModal(true)
        }
        else {
          this.$notify({
            type: 'error',
            text: 'กรุณาติดต่อแอดมิน',
          })
        }
      }
    },
  },
  errorCaptured(error, vm) {
    console.error('Error captured in SettingsBox:', error, vm)
  },
}
</script>

<style lang="scss" scoped>
.settings-box {
  position: fixed;
  right: 0;
  margin-right: 10px;
  margin-left: 10px;
  top: 130px;
  padding: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 365px;
  width: calc(100% - 20px);
  z-index: 30;
}

.slidecontainer {
  width: 100%;
  @apply my-3;
}

.slider {
  @apply mt-4;
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: url('~/public/counter-page/counter.png');
  background-size: cover;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  border: 0;
  background: url('~/public/assets/images/slider-button.png') no-repeat;
  background-size: contain;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  border: 0;
  width: 30px;
  height: 30px;
  border: 0;
  background: url('~/public/assets/images/slider-button.png') no-repeat;
  cursor: pointer;
}
</style>
