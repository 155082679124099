<template>
  <div class="absolute w-48 -right-24 top-12 mb-4 bg-white divide-y dom-border max-h-96 overflow-auto text-center">
    <div v-if="notifications.length === 0">
      <p class="p-3">ไม่มีการแจ้งเตือน</p>
    </div>
    <div v-else v-for="(notification, index) in notifications" :class="index > 0 && 'border-t-2 border-gray-300'">
      <notification-row 
        @clicked="() => onClick(notification)"
        :message="getMessage(notification.fromUser.nickName, notification.type)" 
        :read="(index >= unreadCount)" 
        :clicked="notification.clicked" 
        :time="notification.insertedAt" 
        :notification-id="notification.id"
      />
    </div>
    <div v-if="hasMore" @click="loadMore" class="border-t-2 border-gray-300 py-2">
      ต่อไป
    </div>
  </div>
</template>

<script lang="ts">
import NotificationRow from '~/components/notification/NotificationRow.vue'

export default {
  components: {
    NotificationRow,
  },
  props: {
    notifications: {
      type: Array<any>,
      required: true,
    },
    hasMore: {
      type: Boolean,
      required: true,
    },
    unreadCount: {
      type: Number,
      required: true,
    },
  },
  emits: ['clicked-user', 'clicked-wish'],
  methods: {
    onClick(notification) {
      this.$emit('noti-clicked', notification)
    },
    loadMore() {
      this.$emit('load-more')
    },
    getMessage(nickName: string, type: string) {
      switch (type) {
        case 'friendRequest':
          return `${nickName} ขอเพิ่มคุณเป็นเพื่อน`
        case 'friendRequestAccepted':
          return `${nickName} ยอมรับคำขอเพิ่มเพื่อนของคุณ`
        case 'wishReply':
          return `${nickName} ตอบคำขอพรของคุณ`
        case 'tip':
          return `${nickName} เลี้ยงเครื่องดื่มคุณ`
      }

      return ''
    }
  },
}
</script>