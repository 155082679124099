<template>
  <div data-testid="noti-icon" class="relative">
    <profile-modal
      v-if="showingUserProfile"
      :user-id="showingUserProfile"
      :hide-tip-button="true"
      :hide-report-button="true"
      @close="showingUserProfile = null"
    />
    <reply-wish-notification-modal
      v-if="showingReplyWish"
      :wish-id="showingReplyWish.payload.wishId"
      :wish="showingReplyWish.payload.wish"
      :reply="showingReplyWish.payload.reply"
      :from-nick-name="showingReplyWish.fromUser.nickName"
      @close="showingReplyWish = null"
    />
    <tip-notification-modal
      v-if="showingTipNotification"
      :image-url="showingTipNotification.payload.imageURL"
      :amount="showingTipNotification.payload.amount"
      :from-nick-name="showingTipNotification.fromUser.nickName"
      @close="showingTipNotification = null"
    />
    <button
      @click="onClickNotiIcon"
      class="w-8 h-8 mt-[6px] mr-1 cursor-pointer z-40 bg-white rounded-full"
    >
      <img src="~/public/noti.png" />
    </button>
    <div 
      v-if="unreadCount > 0"
      class="absolute top-0 right-0 w-6 h-6 bg-red-500 rounded-full text-white text-xs flex items-center justify-center"
    >
      {{ unreadCount }}
    </div>
    <notification-panel 
      v-show="isNotiExpanded"
      @noti-clicked="onClickNoti"
      :notifications="notifications" 
      :has-more="nextCursor !== null"
      @load-more="loadMore"
      :unread-count="unreadCount"
    />
  </div>
</template>

<script lang="ts">
import global from '~/mixins/global.js'
import NotificationPanel from '~/components/notification/NotificationPanel.vue'
import ProfileModal from '~/components/ProfileModal.vue'
import ReplyWishNotificationModal from '~/components/notification/ReplyWishNotificationModal.vue'
import TipNotificationModal from '~/components/notification/TipNotificationModal.vue'
import { doc, onSnapshot } from 'firebase/firestore'

export default {
  mixins: [global],
  components: {
    NotificationPanel,
    ProfileModal,
    ReplyWishNotificationModal,
    TipNotificationModal,
  },
  data() {
    return {
      isNotiExpanded: false,
      notifications: [],
      unreadCount: 0,
      nextCursor: null,
      showingUserProfile: null,
      showingReplyWish: false,
      showingTipNotification: false,
      unsubscribeNotification: null,
    }
  },
  mounted() {
    if (this.user?.authId && this.$route.path.startsWith('/app')) {
      this.unsubscribeNotification = onSnapshot(
        doc(this.$firestore, 'notifications', this.user.id),
        (doc) => {
        if (doc.exists()) {
          const data = doc.data()
          this.unreadCount = data.count
        } else {
          this.unreadCount = 0
        }
      },
      (error) => {
          console.error('Notification listener error:', error)
        }
      )
    }
  },
  beforeDestroy() {
    if (this.unsubscribeNotification) {
      this.unsubscribeNotification()
    }
  },
  methods: {
    async onClickNotiIcon () {
      if (this.isNotiExpanded) {
        this.isNotiExpanded = false
      } else {
        const { notifications, nextCursor } = await $fetch('/api/notification/list', {
          method: 'GET',
          baseURL: this.$config.public.appBaseURL,
          headers: this.headers,
        })

        this.notifications = notifications
        this.nextCursor = nextCursor
        this.isNotiExpanded = true
      }

    },
    async loadMore() {
      if (!this.nextCursor) {
        return
      }
      const { notifications, nextCursor } = await $fetch('/api/notification/list?cursor=' + this.nextCursor, {
        method: 'GET',
        baseURL: this.$config.public.appBaseURL,
        headers: this.headers,
      })

      this.notifications = [...this.notifications, ...notifications]
      this.nextCursor = nextCursor
    },
    onClickNoti(notification) {
      this.isNotiExpanded = false
      if (notification.type === 'wishReply') {
        this.showingReplyWish = notification
      } else if (notification.type === 'tip') {
        console.log('notification', notification)
        this.showingTipNotification = notification
      } else {
        console.log('notification.fromUserId', notification.fromUserId)
        this.showingUserProfile = notification.fromUserId
      }
    },
  }
}
</script>