<template>
  <div
    class="min-h-screen h-full flex flex-col justify-center"
    :class="showDarkMode && atApp ? 'bg-dm-bg' : 'bg-cream'"
  >
    <bartender-shake v-if="$store.pageLoading" />
    <h1 class="hidden">
      Drinks On Me
    </h1>
    <h1 class="hidden">
      DrinksOnMe
    </h1>
    <h1 class="hidden">
      drinksonme.live
    </h1>
    <h2 class="hidden">
      Virtual Bar
    </h2>
    <div class="flex justify-center items-center py-14 lg:py-4 px-0 content-body">
      <page-loader v-if="whitePageLoading" />

      <page-loader v-if="!initialized" />
      <server-maintanence v-else-if="$config.public.serverMaintanence" />
      <in-app-page
        v-else-if="inApp"
        @standalone-browser-clicked="standaloneBrowserClicked"
      />
      <div v-else>
        <nav-bar />
        <slot />
      </div>
    </div>
    <Footer
      @about-us-clicked="aboutUsClicked"
      @support-us-clicked="supportUsClicked"
    />
    <!-- <AIBartender class="fixed right-5 bottom-5 z-30" /> -->
    <about-us
      v-if="showAboutUs"
      @close="showAboutUs = false"
    />
    <support-us-modal
      v-if="showSupportUs"
      @close="showSupportUs = false"
    />
  </div>
</template>

<script>
import InApp from 'detect-inapp'
import { logEvent } from 'firebase/analytics'
import InAppPage from '~/components/InAppPage.vue'
import AboutUs from '~/components/AboutUs.vue'
import BarFull from '~/components/BarFull.vue'
import BartenderShake from '~/components/BartenderShake.vue'
import ServerMaintanence from '~/components/maintanence/ServerMaintanence.vue'
import NavBar from '~/components/NavBar.vue'
import Footer from '~/components/Footer.vue'
import SupportUsModal from '~/components/SupportUsModal.vue'
import PageLoader from '~/components/PageLoader.vue'
import global from '~/mixins/global'

export default {
  components: {
    InAppPage,
    AboutUs,
    BartenderShake,
    BarFull,
    Footer,
    ServerMaintanence,
    NavBar,
    SupportUsModal,
    PageLoader,
  },
  mixins: [global],
  data() {
    return {
      inApp: false,
      showAboutUs: false,
      showSupportUs: false,
      initialized: false,
    }
  },
  computed: {
    accessToken() {
      return this.$store.accessToken
    },
    user() {
      return this.$store.user
    },
    counter() {
      return this.$store.counter
    },
    drinks() {
      return this.$store.drinks
    },
    headers() {
      return { Authorization: `Bearer ${this.accessToken}` }
    },
    dailyCloseTime() {
      const date = new Date()
      return (date.getUTCHours() === 21 && date.getUTCMinutes() < 15) || (date.getUTCHours() === 9 && date.getUTCMinutes() < 15)
    },
    whitePageLoading() {
      return this.$store.whitePageLoading
    },
    atApp() {
      return this.$route.path.startsWith('/app')
    },
  },
  watch: {
    accessToken(newVal, oldVal) {
      if (!newVal) {
        this.$store.setUser(null)
      }
      else if (!oldVal) {
        this.getUserProfileOrCreateIfNotExists()
      }
    },
    user(newVal, oldVal) {
      if (newVal && !oldVal) {
        if (this.isLoggedInButHasNotClaimedLoginReward(newVal)) {
          this.claimReward()
        }
      }
    },
  },
  async mounted() {
    this.initialized = false
    setTimeout(() => {
      this.initialized = true
    }, 2000)

    const inapp = new InApp(navigator.userAgent || navigator.vendor || window.opera)
    this.inApp = inapp.isInApp
    this.$supabase.auth.onAuthStateChange((event, _session) => {

      if (event === 'SIGNED_OUT') {
        this.$store.setUser(null)
        this.$store.setAccessToken(null)
        this.$store.setPageLoading(false)
        return
      }

      if (event === 'INITIAL_SESSION') {
        this.$store.setAccessToken(_session.access_token)
        return
      }

      if (event === 'SIGNED_IN') {
        this.$store.setAccessToken(_session.access_token)
        return
      }

      if (event === 'TOKEN_REFRESHED') {
        this.$store.setAccessToken(_session.access_token)
        return
      }

      // handle all the events
      this.$notify({
        type: 'error',
        text: 'unhandled event: ' + event,
      })
    })
  
    const { data: { session } } = await this.$supabase.auth.getSession()
    if (session) {
      this.$store.setAccessToken(session.access_token)
    } else {
      this.initialized = true
    }
    if (this.drinks.length === 0) {
      const data = await $fetch('/api/drinks', {
        method: 'GET',
        baseURL: this.$config.public.appBaseURL,
        headers: this.headers,
      })
      this.$store.setDrinks(data.drinks)
    }
  },
  beforeExit() {
    this.removeAppUrlOpenListener()
  },
  methods: {
    standaloneBrowserClicked() {
      this.inApp = false
      logEvent(this.$analytics, 'mistake_browser', {
        userAgent: navigator.userAgent,
      })
    },
    aboutUsClicked() {
      this.showAboutUs = true
    },
    supportUsClicked() {
      this.showSupportUs = true
    },
    async getUserProfileOrCreateIfNotExists() {
      try {
        const data = await $fetch('/api/profile', {
          method: 'GET',
          baseURL: this.$config.public.appBaseURL,
          headers: this.headers,
        })
        this.$store.setUser(data)
        this.initialized = true
        return { data }
      }
      catch (error) {
        switch (error.data?.message) {
          case 'unauthorized':
            if (this.accessToken) {
              await this.$supabase.auth.signOut()
              location.reload()
            }
            else {
              this.$store.setAccessToken(null)
            }
            break
          case 'not-found':
            if (this.accessToken) {
              await this.createUser()
            }
            break
          case 'banned':
            this.$router.push('/banned')
            break
          case 'bar-closed':
            this.$notify({
              type: 'info',
              text: 'บาร์ปิดปรับปรุงครึ่ง ชม. นะคะ',
            })
            break
          default:
            this.$notify({
              type: 'error',
              text: 'กรุณา refresh แล้วลองใหม่อีกครั้งค่ะ',
            })
            location.reload()
            break
        }
        this.initialized = true
      }
    },
    async createUser() {
      try {
        const data = await $fetch('/api/user', {
          method: 'POST',
          baseURL: this.$config.public.appBaseURL,
          headers: this.headers,
        })
        this.$store.setUser(data)
      }
      catch (error) {
        switch (error.data?.message) {
          case 'bar-closed':
            this.$notify({
              type: 'info',
              text: 'บาร์ปิดปรับปรุงครึ่ง ชม. นะคะ',
            })
            break
          default:
            this.$notify({
              type: 'error',
              text: 'กรุณา refresh แล้วลองใหม่อีกครั้งค่ะ',
            })
            break
        }
      }
    },
    isLoggedInButHasNotClaimedLoginReward(user) {
      return (
        user
        && user.authId
        && user.rewardInventory
        && user.rewardInventory.find(
          r => r.rewardName === 'first-time-discord-login',
        ) === undefined
      )
    },
    async claimReward() {
      try {
        const data = await $fetch(
          '/api/rewards/first-time-discord-login',
          {
            method: 'POST',
            baseURL: this.$config.public.appBaseURL,
            headers: this.headers,
          },
        )
        console.log('data1', data)
        this.$store.setUser(data)
      }
      catch (error) {
        console.error(error.data)
      }
    },
  },
}
</script>

<style lang="scss">
.dom-notification {
  @apply text-sm m-2 mt-4 p-3 text-black border-2 border-black flex items-center gap-3 shadow-md;

  background: #E2E4E3;

  &.success {
    background: #C1FFDE;
   }
  &.error {
    background: #FFD0C1;
   }
}
.content-body {
  min-height: calc(100vh - 86px);
}
</style>
