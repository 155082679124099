<template>
  <modal
    @close="$emit('close')"
  >
    <img
      :src="`/event/christmas/${wishId}.png`"
      class="w-32"
    >
    <p class="text-sm mb-2">
      ของคุณ
    </p>
    <div 
      class="w-full h-24 border-2 max-w-96 border-black rounded-3xl p-2 mt-2"
    >
      {{ wish }}
    </div>
    <p class="text-sm mb-2 mt-4">
      คุณ {{ fromNickName }} ตอบว่า
    </p>
    <div 
      class="w-full h-24 border-2 max-w-96 border-black rounded-3xl p-2 mt-2"
    >
      {{ reply }}
    </div>
    <new-banyen-button
      class="mt-6 px-8"
      @click="$emit('close')"
    >
      ปิด
    </new-banyen-button>
  </modal>
</template>


<script lang="ts">
import Modal from '~/components/common/Modal.vue'
import NewBanyenButton from '~/components/common/NewBanyenButton.vue'
export default {
  components: {
    Modal,
    NewBanyenButton,
  },
  props: {
    wishId: {
      type: String,
      required: true,
    },
    wish: {
      type: String,
      required: true,
    },
    reply: {
      type: String,
      required: true,
    },
    fromNickName: {
      type: String,
      required: true,
    },
  },
}
</script>