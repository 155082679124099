<template>
  <modal
    @close="$emit('close')"
  >
    <p class="text-2xl mt-4">
      คุณ <span class="text-banyen font-semibold">"{{ fromNickName }}"</span> 
    </p>
    <p class="text-2xl mt-1">
      เลี้ยงเครื่องดื่มคุณ
    </p>
    <p class="text-xl mb-4 mt-4">
      เพื่อเป็นกำลังใจ หรือแสดงคำขอบคุณ
    </p>
    <div class="flex justify-center items-center border-2 border-black rounded-3xl w-32 h-32">
      <img
        :src="imageUrl"
        class="w-16 "
      >
    </div>
    <div 
      class="w-full p-2 mt-2 text-xl"
    >
      และคุณได้ติ๊บเป็นจำนวน <img src="/tip/tip-icon.png" class="w-6 inline-block -mx-1 relative top-[-1px]" /> <span class="font-semibold">x{{ amount }}</span>
    </div>
    <div class="flex justify-center items-center">*สามารถนำไปใช้ซื้อสกินพิเศษใน shop ได้</div>
    <new-banyen-button
      class="mt-6"
      @click="$emit('close')"
    >
      ปิด
    </new-banyen-button>
  </modal>
</template>


<script lang="ts">
import Modal from '~/components/common/Modal.vue'
import NewBanyenButton from '~/components/common/NewBanyenButton.vue'
export default {
  components: {
    Modal,
    NewBanyenButton,
  },
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    fromNickName: {
      type: String,
      required: true,
    },
  },
}
</script>